<template>
  <base-layout>
    <RidersListIndex
      :fst-id="`rider-list-index`"
      :pageTitle="$t('components.riderManagement.headline')"
    />
  </base-layout>
</template>

<script>
import BaseLayout from '@/views/shared/BaseLayout.vue'
import RidersListIndex from '@/composites/rider/index/Index.vue'

export default {
  name: 'RiderList',
  components: {
    BaseLayout,
    RidersListIndex,
  },
}
</script>
