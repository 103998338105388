<template>
  <base-layout>
    <RidersListIndex
      :fst-id="`delete-requested-riders`"
      :pageTitle="
        $t('components.riderManagement.deleteRequestedRidersHeadline')
      "
      :qso="qso"
      :endpoint="endpoint"
      type="deleteRequestRiders"
    />
  </base-layout>
</template>

<script>
import BaseLayout from '@/views/shared/BaseLayout.vue'
import RidersListIndex from '@/composites/rider/index/Index.vue'
import { RiderUserConfig } from '@/config/RiderUserConfig'
export default {
  name: 'DeleteRequestedRiders',
  components: {
    BaseLayout,
    RidersListIndex,
  },
  data() {
    return {
      title: 'Riders',
      endpoint: null,
      qso: { append: '', prepend: '' },
    }
  },
  created() {
    this.generateEndPoint()
  },
  methods: {
    generateEndPoint() {
      this.endpoint =
        RiderUserConfig.api.index + `?is_soft_delete_requested=true&`
    },
  },
}
</script>
