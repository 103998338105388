<template>
  <base-layout>
    <RidersListIndex
      :fst-id="`deleted-riders`"
      :pageTitle="$t('components.riderManagement.deletedRidersHeadline')"
      :endpoint="endpoint"
      type="deletedRiders"
    />
  </base-layout>
</template>

<script>
import BaseLayout from '@/views/shared/BaseLayout.vue'
import RidersListIndex from '@/composites/rider/index/Index.vue'
import { useEndpoints } from '@/composables'

export default {
  name: 'DeletedRiders',
  components: {
    BaseLayout,
    RidersListIndex,
  },
  data() {
    return {
      endpoint: null,
    }
  },
  created() {
    this.generateEndPoint()
  },
  methods: {
    generateEndPoint() {
      this.endpoint = useEndpoints.rider.deletedRidersIndex()
    },
  },
}
</script>
